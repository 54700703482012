module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"trackingIds":["G-Y6015SVNDW"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito:400,700,800,900"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-CA","en-GB","en-US","fr-FR","ja-JP"],"siteUrl":"https://loopmobileapp.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/","languages":["en-CA","en-GB","en-US","fr-FR","ja-JP"]},{"matchPath":"/:lang?/faq","languages":["en-CA","en-GB","en-US","fr-FR","ja-JP"]},{"matchPath":"/:lang?/contact-us","languages":["en-CA","en-GB","en-US","fr-FR","ja-JP"]},{"matchPath":"/privacy-policy/","languages":[""]},{"matchPath":"/:lang?/privacy-policy/","languages":[""]},{"matchPath":"/:lang?/do-not-sell/","languages":[""]},{"matchPath":"/:lang?/how-it-works/","languages":[""]}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","linkImagesToOriginal":false,"maxWidth":200,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Loop","short_name":"loop","start_url":"/","background_color":"#FFF","theme_color":"#4CBCFD","display":"minimal-ui","icon":"/opt/build/repo/src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e187698bc7b342f9969c6b0090431f6e"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
